<template>
  <div class="flex items-center" :class="{'pointer-events-none opacity-50': !data}">
    <feather-icon icon="DownloadIcon" svgClasses="h-4 w-4" class="mr-2"/>
    <span class="whitespace-no-wrap" @click="exportToCsv">Export to CSV</span>
  </div>
</template>

<script>
import { exportToCsv } from '@/utilities/common/global-methods'

export default {
  name: 'ExportCsv',
  props: {
    data: { required: true, default: null, type: Array }
  },
  data () {
    return {}
  },
  methods: {
    exportToCsv () {
      exportToCsv(this.data)
    }
  }
}
</script>
